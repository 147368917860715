import { API, Cache } from "aws-amplify";
import config from "../config/";
import dexie from "dexie";
import moment from "moment";
import _ from "lodash";

import {GA} from "../components/GA/index";

import {
  // APPLY_FOR_A_JOB, not used?
  APPLY_FOR_A_JOB_UPDATE,
  FETCH_APPLIED_JOBS,
  FETCH_FAVOURITED_JOBS,
  SET_FAVOURITE_JOB,
  SET_SELECTED_JOB,
  FETCH_SKILLS_LIST,
  FETCH_JOB_CATEGORIES,
  FETCH_QUALIFICATIONS,
  FETCH_ALL_JOBS,
  FETCH_NEARME_JOBS,
  ADD_FILTER,
  JOBS_FILTER,
  SET_APPLICATIONS_NOTIFICATION,
  FETCH_CENTRES,
  LOADED_CONTENT_DASHBOARD,
  TOGGLE_LOADING,
  MARK_ITEM_READ,
  UPDATE_FULL_DESCRIPTION,
  OPTIONAL_SKILL_CHECK,
  FETCH_INDIVIDUAL_JOBS,
  UPDATE_JOB_CATEGORIES,
  UPDATE_LOAD_MORE_COUNT,
  ADD_TO_VIEWED_LIST,
  FETCH_ALL_JOBS_COMPLETE
} from "./types";

import { recordJobClick } from "./analytics_actions";
import {fetchCoins} from "./reward_actions";
import { updateReverseMineIndexes, updateOccupationBlacklist, getinternalMobilityRoles } from "./user_actions";

const loadDB = async () => {
  
  let db = new dexie("Jobs");
  db.version(1).stores({
    jobCategories: "++id, value, key",
    jobs: "++id, value, key",
    jobsNearMe: "++id, value, key",
    quals: "++id, value, key"
  });

  return db;
}

const clearTableDB = async (db,store) => {
  await db[store].clear();
  // console.dir(db[store]);
}

const saveValuesToDB = async (db, collection, store) => {
  let arr = [];
  _.each(collection, (data, key) => {
    // console.log(data, key);
    arr.push({value: JSON.stringify(data), key: key});
  });
  // console.log(store);
  await db[store].bulkAdd(arr);
}

const loadFromDB = async (db, store, type) => {
  
  let obj = {};
  if (type === "arr") {
    obj = [];
  }
  await db[store].each((data) => {
    if (type !== "arr")  {
      obj[data.key] = JSON.parse(data.value);
    } else {
      obj.push(JSON.parse(data.value));
    }
  })
  return obj;
}


export const toggleLoading = (setToggle) => async (dispatch, getState) => {
  dispatch({ type: TOGGLE_LOADING, setToggle});
};

export const manualOccSearch = (value) => async (dispatch, getState) => { //value is an array of occs to search
  try {
    dispatch(toggleLoading(true));
    const {
      jobs:{
        allJobCategories = []
      }
    } = getState();

    for (const occ of value){
      const foundJobCat = allJobCategories.find((item) => item.occupation === occ);
      if(foundJobCat){
        await Promise.all([dispatch(loadOccpationJobs(foundJobCat.parent_occupation ? foundJobCat.parent_occupation : foundJobCat.occupation, foundJobCat.occKey))]);
      }
      else{
        await Promise.all([dispatch(getSearchedCategoryAndJobs(occ))]);
      } 
      //pass though blacklist to make sure we remove it from there to show the card
      await Promise.all([dispatch(updateOccupationBlacklist(occ, true))]); 
    }

  } catch (error) {
    console.log(error)
  } finally {
    dispatch(toggleLoading(false));

  }
}


export const preLoadJobsSearch = () => async (dispatch, getState) => {
  dispatch(toggleLoading(true));
  await Promise.all([dispatch(getJobCategories())]);
  dispatch(toggleLoading(false));
  dispatch(updateReverseMineIndexes())
};

export const setSelectedJobIdFromBubbleChart = (jobId) => async (dispatch) => {
  dispatch({ type: SET_SELECTED_JOB, jobId });
}

export const setApplicationNotification= () => async (dispatch) => {
  dispatch({ type: SET_APPLICATIONS_NOTIFICATION });
}

export const setSelectedJob = (
  jobId,
  jobTitle,
  occupation,
  tier_one_category,
  isQualification = false
) => async (dispatch) => {
  dispatch(
    recordJobClick(
      "viewDetails",
      jobTitle,
      occupation,
      tier_one_category,
      isQualification
    )
  );
  
  dispatch({ type: ADD_TO_VIEWED_LIST, jobId });
  
  dispatch({ type: SET_SELECTED_JOB, jobId });
  let ga = new GA();
  ga.setUserFromCache();

  if (isQualification){
    ga.trackEvent({
      category: "Qualification",
      action: "Selected",
      label: `jobId: ${jobId} jobTitle: ${jobTitle} occupation: ${occupation}`
    });
  } else {
    ga.trackEvent({
      category: "Job",
      action: "Selected",
      label: `jobId: ${jobId} jobTitle: ${jobTitle} occupation: ${occupation}`
    });
  }

  try {
    await API.post(config.API_NAME, "/jobitems/viewed", {
      body: {
        jobId: jobId,
        jobTitle,
        occupation,
        tier_one_category,
        isQualification,
      },
    });
  } catch (e) {}
  dispatch(fetchCoins())
};


export const setCourseViewed  = (course) => async (dispatch) => {

  try {
    await API.post(config.API_NAME, "/jobitems/courseonline/viewed", {
      body: course
    });
  } catch (e) {}
}

export const setJobViewed = (
  jobId,
  jobTitle,
  occupation,
  tier_one_category,
  isQualification = false) => async (dispatch) => {

      dispatch(
    recordJobClick(
      "viewDetails",
      jobTitle,
      occupation,
      tier_one_category,
      isQualification
    )
  );

    try {
      await API.post(config.API_NAME, "/jobitems/viewed", {
        body: {
          jobId: jobId,
          jobTitle,
          occupation,
          tier_one_category,
          isQualification,
        },
      });
    } catch (e) {}
    dispatch(fetchCoins())
}

export const getJobCategories = () => async (dispatch, getState) => {
  const {
    user: {
      contractDetails = {},
      skills_names,
      updatedAt,
      rawdata: {
        latestAssessmentResult = {},
        occupations,
        occupationsOriginal,
        desiredOccupations,
        desiredOccupationsOriginal,
        desiredExp,
        occupationsExtended,
        qualifications,
        userID,
        internalMobilityProvider
      } = {},
    },
  } = getState();
  const { jobsType = 0 } = contractDetails;
  let internalMobilityRoles = []
  let internalMobilityRolesList = []

  if(internalMobilityProvider){
    internalMobilityRoles = await dispatch(getinternalMobilityRoles());
    internalMobilityRolesList = internalMobilityRoles.map((item) => item.occupation[0])
  }
  try {


    let payload = {
      updatedAt,
      latestAssessmentResult,
      occupations,
      occupationsExtended,
      desiredExp,
      userID,
      qualifications,
      desiredOccupations,
      occupationsOriginal: occupationsOriginal,
      desiredOccupationsOriginal: desiredOccupationsOriginal,
      calculateQualifications: jobsType !== 0 ? true : false,
      skills_names,
    };

    
    if(internalMobilityProvider){
      payload.role_skills = {}
      for(const role of internalMobilityRoles){
        let tmpSkills = []
        if(role.skills && role.skills.length > 0){
          for(const skillObj of role.skills){
            if(skillObj.type === "essential"){
              tmpSkills.push(skillObj.skill)
            }
          }
        }
        payload.role_skills[role.occupation[0]] = tmpSkills
      }
    }
    
    if (!Cache.getItem("jobCategoriesV2")) {
      let { jobs } = await API.post(
        config.API_NAME,
        "/jobs/query/all_occupations",
        {
          body: {
            data: payload,
          },
        }
      );
      jobs.searched_occupations = [];
      if(internalMobilityProvider){
        let existingOccs = []
        for(const key of Object.keys(jobs)){
          jobs[key] = jobs[key].filter((item) => internalMobilityRolesList.includes(item.occupation) || (item.alt_labels && item.alt_labels.some(r=> internalMobilityRolesList.includes(r)))).map((item) => {
            const roleTitle = internalMobilityRoles.find((_item) => _item.occupation.includes(item.occupation));
            existingOccs.push(item.occupation);
            existingOccs = [...existingOccs, item.occupation, ...roleTitle.preferredLabels]
            existingOccs.push(item.occupation);
            return {
              ...item,
              roleTitle: roleTitle ? roleTitle.roleName : false
            }
          })
        }

        for (const idx in payload.desiredExp){
            payload.occupations.push(payload.desiredOccupations[idx])
            payload.occupationsOriginal.push(payload.desiredOccupationsOriginal[idx])
        }
        payload.desiredExp = []
        payload.desiredOccupations = []
        payload.desiredOccupationsOriginal = []
        for (const role of internalMobilityRoles){
          if(!existingOccs.includes(role.occupation[0])){
            payload.desiredExp.push(false)
            payload.desiredOccupations.push(role.preferredLabels[0])
            payload.desiredOccupationsOriginal.push(role.occupation[0])
          }
        }
        if(payload.desiredExp.length > 0){
          let internalOccs = await API.post(
            config.API_NAME,
            "/jobs/query/all_occupations",
            {
              body: {
                data: payload,
              },
            }
          );
          if(internalOccs && internalOccs.jobs){       
              internalOccs.jobs.desired_occupations = internalOccs.jobs.desired_occupations.filter((item) => internalMobilityRolesList.includes(item.occupation) || (item.alt_labels && item.alt_labels.some(r=> internalMobilityRolesList.includes(r)))).map((item) => {
              const roleTitle = internalMobilityRoles.find((_item) => _item.occupation.includes(item.occupation) || (item.alt_labels && item.alt_labels.some(r=> _item.occupation.includes(r))));
                return {
                  ...item,
                  roleTitle: roleTitle ? roleTitle.roleName : false
                }
              })
            jobs.skills_backpack_occupations.push(...internalOccs.jobs.desired_occupations)
            jobs.skills_backpack_occupations = [...new Set(jobs.skills_backpack_occupations)]
          }
        } 
      }

      dispatch({ type: FETCH_JOB_CATEGORIES, 
        jobs,
        preferredOccupations:  desiredOccupations,
        previousOccupations: occupations,
        desiredExp: desiredExp
      });
        
      const {
        desired_occupations = [],
        interest_occupations = [],
        past_occupations = [],
        skills_backpack_occupations = [],
        styles_occupations = [],
      } = jobs;

      if (
        desired_occupations.length >= 1 ||
        interest_occupations.length >= 1 ||
        past_occupations.length >= 1 ||
        skills_backpack_occupations.length >= 1 ||
        styles_occupations.length >= 1
      ) {
        let db = await loadDB();
        await clearTableDB(db, "jobCategories");
        await saveValuesToDB(db, jobs, "jobCategories");
          
        Cache.setItem("jobCategoriesV2", true, {
          expires: moment().add(2, "days").valueOf(),
        });

      }
    } else {
      let db = await loadDB();
      let jobCategories = await loadFromDB(db,"jobCategories");

      dispatch({
        type: FETCH_JOB_CATEGORIES,
        jobs: jobCategories,
        preferredOccupations:  desiredOccupations,
        previousOccupations: occupations,
        desiredExp: desiredExp
      });
    }
  } catch (e) {
    // TODO
    console.log(e);
  }
};

export const getRecommendedJobsNearMe = () => async (dispatch, getState) => {
  const {
    user: {
      skills_names,
      contractDetails = {},
      rawdata: {
        latestAssessmentResult = {},
        occupations,
        desiredOccupations,
        desiredExp,
        occupationsExtended,
        qualifications,
      } = {},
    },
  } = getState();

  const { jobsType = 0 } = contractDetails;

    const payload = {
      latestAssessmentResult,
      occupations,
      occupationsExtended,
      desiredExp,
      qualifications,
      desiredOccupations,
      calculateQualifications: jobsType !== 0 ? true : false,
      skills_names
    };

    if (payload.latestAssessmentResult.interests){
      payload.latestAssessmentResult.interests.salary = ["0"];
    }

    if (payload.latestAssessmentResult.interests){
      payload.latestAssessmentResult.interests.contract = ["Either"];
    }

    // payload.occupations = desiredOccupations;
    
  try {
    

    if (!Cache.getItem("jobs_nearmeV2")) {
      const { jobs } = await API.post(config.API_NAME, "/jobs/query/jobs_near_me", {
        body: {
          data: payload,
        },
      });

      jobs.searched_occupations = []
      dispatch({ type: FETCH_NEARME_JOBS, jobs });

      //add to cache only if exist
      if (jobs.length >= 1){
        let db = await loadDB();
        await clearTableDB(db, "jobsNearMe");
        await saveValuesToDB(db, jobs, "jobsNearMe");
        Cache.setItem("jobs_nearmev2", true, {
          expires: moment().add(30, "m").valueOf(),
        });
        await API.put(config.API_NAME, "/users/nearmestatus", {
          body: {
            "nojobsnearme": false,
          },
        }).then((res) => {
        }).catch((err) => {
          
        });
      } else {
        await API.put(config.API_NAME, "/users/nearmestatus", {
          body: {
            "nojobsnearme": true,
          },
        }).then((res) => {
        }).catch((err) => {
          
        });
        dispatch({
          type: FETCH_NEARME_JOBS,
          jobs: [],
        });
      }
      
    } else {
      let db = await loadDB();
      let jobsNearMe = await loadFromDB(db,"jobCategories");

      dispatch({
        type: FETCH_NEARME_JOBS,
        jobs: jobsNearMe,
      });
    }
  } catch (e) {
    // TODO
    console.log(e);
  }
};

export const getInitialJobs = (occupationKey = "desired_occupations") => async (dispatch, getState) => {
  const {
    jobs: { rawJobCategories = {}, allJobCategories=[] },
    user: { rawdata: { latestAssessmentResult = {}, occupations, occupationsOriginal, desiredOccupations, desiredOccupationsOriginal, internalMobilityProvider} = {}, skills_names, providerDetails={}},
  } = getState();
  let viewPermissions = {};
  if (providerDetails && providerDetails.viewPermissions) {
    viewPermissions = providerDetails.viewPermissions;
  }
  dispatch({type: FETCH_ALL_JOBS_COMPLETE, data:true});

  var clonedRawJobCategories = _.cloneDeep(rawJobCategories);
  const userOccs = {occupations, occupationsOriginal, desiredOccupations, desiredOccupationsOriginal}
  var interests = latestAssessmentResult.interests;

  const payload = {
    provider: providerDetails.sort,
    latestAssessmentResult: {
      interests,
    },
    skills_names,
    desired_occupations: [],
    interest_occupations: [],
    past_occupations: [],
    skills_backpack_occupations: [],
    styles_occupations: [],
  };

  if(internalMobilityProvider){
    payload.provider = internalMobilityProvider
    payload.isInternal = true
  }

  try {
    if (!Cache.getItem("jobsV2")) {

      let combinedJobs = {searched_occupations :[], desired_occupations: [], interest_occupations: [], past_occupations: [], skills_backpack_occupations: [], styles_occupations:[]}
      if(payload.isInternal){
        let jobCatKey = {}
        for (const [_key, _value] of Object.entries(clonedRawJobCategories)) {
          for (const occ of _value){
            if(!jobCatKey[occ.occupation]){
              jobCatKey[occ.occupation] = _key
            }
          }
        }
        const { jobs } = await API.post(config.API_NAME, "/jobs/query/internal_mobility_jobs", {
          body: {
            data: payload
          },
        });
        console.log(clonedRawJobCategories)
        console.log(jobCatKey)
        if(jobs && jobs.length > 0){
          for (const job of jobs){
            try{
              combinedJobs[jobCatKey[job.occupation]].push(job)
            }
            catch (e) {
              continue
            }
          }
          dispatch({
            type: FETCH_ALL_JOBS,
            jobs: combinedJobs,
          });
          dispatch({ type: JOBS_FILTER, occupationCategoryFilterChange:true });
        } 

      }
      else{
        for (const [_key, _value] of Object.entries(clonedRawJobCategories)) {
          if (_key == occupationKey){
            const parent_occupations = [...new Set(_value.map((item) => item.parent_occupation))];
            for (const parent_occupation of parent_occupations){
              const { jobs } = await API.post(config.API_NAME, "/jobs/query/all_jobs", {
                body: {
                  data: {...payload, [_key] : _value.filter((item) => item.parent_occupation === parent_occupation)}
                },
              });
              let filteredJobs = jobs[_key];
              if(viewPermissions && viewPermissions.jobsCheckbox){
                filteredJobs = filteredJobs.filter((item) => item.vacancy_flag);
              }
              else if(viewPermissions && viewPermissions.promotedJobsCheckbox){
                filteredJobs = filteredJobs.filter((item) => !item.vacancy_flag);
              }

              combinedJobs[_key] = [...combinedJobs[_key], ...filteredJobs]
              dispatch(
                { 
                  type: FETCH_INDIVIDUAL_JOBS, 
                  jobs:
                    { desired_occupations: [],
                      interest_occupations: [],
                      past_occupations: [],
                      skills_backpack_occupations: [],
                      styles_occupations: [],
                      searched_occupations :[],
                      [_key]: filteredJobs 
                    } 
                  , jobsKey: _key});
                if(parent_occupation === parent_occupations[0] && occupationKey === "desired_occupations" && filteredJobs.length > 0){
                  const preSeletedOcc = _value[0].occupation
                  if(preSeletedOcc){
                    dispatch({ type: JOBS_FILTER, occupation:preSeletedOcc, isRemove:false, userOccs, occupationFilterChange:true});
                  }
                  else{
                    dispatch({ type: JOBS_FILTER, occupationCategoryFilterChange:true });
                  }
                }
                else{
                  dispatch({ type: JOBS_FILTER, occupationCategoryFilterChange:true });
                }
            }
          }
        }
      }

      //add to cache only if exist
      const {
        desired_occupations = [],
        interest_occupations = [],
        past_occupations = [],
        skills_backpack_occupations = [],
        styles_occupations = [],
      } = combinedJobs;

      if (
        desired_occupations.length >= 1 ||
        interest_occupations.length >= 1 ||
        past_occupations.length >= 1 ||
        skills_backpack_occupations.length >= 1 ||
        styles_occupations.length >= 1
      ) {
        let db = await loadDB();
        await clearTableDB(db, "jobs");
        await saveValuesToDB(db, combinedJobs, "jobs");

        Cache.setItem("jobsV2", true, {
          expires: moment().add(30, "m").valueOf(),
        });
        dispatch({type: FETCH_ALL_JOBS_COMPLETE, data:false});
      }
      else if(occupationKey === "desired_occupations"){
        dispatch(getInitialJobs("past_occupations"));
      }
      else if(occupationKey === "past_occupations"){
        dispatch(getInitialJobs("skills_backpack_occupations"));
      }
      else{
        dispatch({type: FETCH_ALL_JOBS_COMPLETE, data:false});
      }
    } else {
      let db = await loadDB();
      let jobsCache = await loadFromDB(db,"jobs");

      dispatch({
        type: FETCH_ALL_JOBS,
        jobs: jobsCache,
      });
      const preSeletedOcc = clonedRawJobCategories.desired_occupations[0].occupation
      const addFilter = preSeletedOcc ? allJobCategories.find((item) => (preSeletedOcc === item.occupation || preSeletedOcc === item.parent_occupation || (item.alt_labels && item.alt_labels.includes(preSeletedOcc))) && item.jobCount > 0) : false; 
      if(preSeletedOcc && addFilter){
        dispatch({ type: JOBS_FILTER, occupation:preSeletedOcc, isRemove:false, userOccs, occupationFilterChange:true});
      }
      else{
        dispatch({ type: JOBS_FILTER, occupationCategoryFilterChange:true });
      }
      dispatch({type: FETCH_ALL_JOBS_COMPLETE, data:false});
    }
  } catch (e) {
    // TODO
    dispatch({
      type: FETCH_ALL_JOBS,
      jobs: []
    });
    console.log(e);
  }
};

export const loadOccpationJobs = (parent_occupation, occupation_catogery) => async (dispatch, getState) => {
  // console.log("loadOccpationJobs")
  // console.log(parent_occupation, occupation_catogery)
  const {
    jobs: { rawJobCategories = {} },
    user: { rawdata: { latestAssessmentResult = {}, occupations, occupationsOriginal, desiredOccupations, desiredOccupationsOriginal} = {}, skills_names, providerDetails={}},
  } = getState();
  let viewPermissions = {};
  if (providerDetails && providerDetails.viewPermissions) {
    viewPermissions = providerDetails.viewPermissions;
  }
  dispatch({type: FETCH_ALL_JOBS_COMPLETE, data:true});
  dispatch(toggleLoading(true))

  var clonedRawJobCategories = _.cloneDeep(rawJobCategories);
  const userOccs = {occupations, occupationsOriginal, desiredOccupations, desiredOccupationsOriginal}
  var interests = latestAssessmentResult.interests;

  const payload = {
    provider: providerDetails.sort,
    latestAssessmentResult: {
      interests,
    },
    skills_names,
    desired_occupations: [],
    interest_occupations: [],
    past_occupations: [],
    skills_backpack_occupations: [],
    styles_occupations: [],
  };

  try {
    const occsToSearch = clonedRawJobCategories[occupation_catogery].filter((item) => item.parent_occupation ? item.parent_occupation === parent_occupation : item.occupation === parent_occupation )
    // console.log(occsToSearch)
    const { jobs } = await API.post(config.API_NAME, "/jobs/query/all_jobs", {
      body: {
        data: {...payload, [occupation_catogery] : occsToSearch}
      },
    });

    let filteredJobs = jobs[occupation_catogery];
    if(viewPermissions && viewPermissions.jobsCheckbox){
      filteredJobs = filteredJobs.filter((item) => item.vacancy_flag);
    }
    else if(viewPermissions && viewPermissions.promotedJobsCheckbox){
      filteredJobs = filteredJobs.filter((item) => !item.vacancy_flag);
    }
    dispatch(
      { 
        type: FETCH_INDIVIDUAL_JOBS, 
        jobs:
          { desired_occupations: [],
            interest_occupations: [],
            past_occupations: [],
            skills_backpack_occupations: [],
            styles_occupations: [],
            searched_occupations :[],
            [occupation_catogery]: filteredJobs 
          } 
        , jobsKey: occupation_catogery
    });
    const preSeletedOcc = occsToSearch[0].occupation
    if(preSeletedOcc){
      dispatch({ type: JOBS_FILTER, occupation:preSeletedOcc, isRemove:false, userOccs, occupationFilterChange:true});
    }
    else{
      dispatch({ type: JOBS_FILTER, occupationCategoryFilterChange:true });
    }

    let db = await loadDB();
    let combinedJobs = await loadFromDB(db,"jobs");
    if (!combinedJobs){
      combinedJobs = {};
    }

    let idList = []
    let filteredList = []
    filteredJobs.forEach((item) => {
      if (!idList.includes(item.jobId)){
        idList.push(item.jobId)
        filteredList.push(item)
      }
    })

    try {
      combinedJobs[occupation_catogery].push(...filteredList)
      combinedJobs[occupation_catogery] = [...new Set(combinedJobs[occupation_catogery])]
    }
    catch {
      combinedJobs.searched_occupations = filteredList
    }
    await clearTableDB(db, "jobs");
    await saveValuesToDB(db, combinedJobs, "jobs");

  } catch (e) {
    console.log(e);
  }
  dispatch({type: FETCH_ALL_JOBS_COMPLETE, data:false});
  dispatch(toggleLoading(false))
};

export const getRecommendedJobs = () => async (dispatch, getState) => {
  const {
    jobs: { rawJobCategories = {} },
    user: { rawdata: { latestAssessmentResult = {}, occupations, occupationsOriginal, desiredOccupations, desiredOccupationsOriginal} = {}, skills_names, providerDetails={}},
  } = getState();
  let viewPermissions = {};
  if (providerDetails && providerDetails.viewPermissions) {
    viewPermissions = providerDetails.viewPermissions;
  }
  dispatch({type: FETCH_ALL_JOBS_COMPLETE, data:true});

  var clonedRawJobCategories = _.cloneDeep(rawJobCategories);
  const userOccs = {occupations, occupationsOriginal, desiredOccupations, desiredOccupationsOriginal}
  var interests = latestAssessmentResult.interests;

  const payload = {
    provider: providerDetails.sort,
    latestAssessmentResult: {
      interests,
    },
    skills_names,
    desired_occupations: [],
    interest_occupations: [],
    past_occupations: [],
    skills_backpack_occupations: [],
    styles_occupations: [],
  };

  try {
    if (!Cache.getItem("jobsV2")) {

      let combinedJobs = {searched_occupations :[]}
      for (const [_key, _value] of Object.entries(clonedRawJobCategories)) {
        if (_key !== "searched_occupations"){
          const { jobs } = await API.post(config.API_NAME, "/jobs/query/all_jobs", {
            body: {
              data: {...payload, [_key] : _value}
            },
          });
          let filteredJobs = jobs[_key];
          if(viewPermissions && viewPermissions.jobsCheckbox){
            filteredJobs = filteredJobs.filter((item) => item.vacancy_flag);
          }
          else if(viewPermissions && viewPermissions.promotedJobsCheckbox){
            filteredJobs = filteredJobs.filter((item) => !item.vacancy_flag);
          }


          combinedJobs[_key] = filteredJobs 
          dispatch(
            { 
              type: FETCH_INDIVIDUAL_JOBS, 
              jobs:
                { desired_occupations: [],
                  interest_occupations: [],
                  past_occupations: [],
                  skills_backpack_occupations: [],
                  styles_occupations: [],
                  searched_occupations :[],
                  [_key]: filteredJobs
                } 
              , jobsKey: _key});
          if(_key == "desired_occupations"){
            const preSeletedOcc = _value[0].occupation
            if(preSeletedOcc){
              dispatch({ type: JOBS_FILTER, occupation:preSeletedOcc, isRemove:false, userOccs, occupationFilterChange:true});
            }
            else{
              dispatch({ type: JOBS_FILTER, occupationCategoryFilterChange:true });
            }
          }
        }
      }

      //add to cache only if exist
      const {
        desired_occupations = [],
        interest_occupations = [],
        past_occupations = [],
        skills_backpack_occupations = [],
        styles_occupations = [],
      } = combinedJobs;

      if (
        desired_occupations.length >= 1 ||
        interest_occupations.length >= 1 ||
        past_occupations.length >= 1 ||
        skills_backpack_occupations.length >= 1 ||
        styles_occupations.length >= 1
      ) {
        let db = await loadDB();
        await clearTableDB(db, "jobs");
        await saveValuesToDB(db, combinedJobs, "jobs");

        Cache.setItem("jobsV2", true, {
          expires: moment().add(30, "m").valueOf(),
        });
        dispatch({type: FETCH_ALL_JOBS_COMPLETE, data:false});
      }
    } else {
      let db = await loadDB();
      let jobsCache = await loadFromDB(db,"jobs");

      dispatch({
        type: FETCH_ALL_JOBS,
        jobs: jobsCache,
      });
      const preSeletedOcc = clonedRawJobCategories.desired_occupations[0].occupation
      if(preSeletedOcc){
        dispatch({ type: JOBS_FILTER, occupation:preSeletedOcc, isRemove:false, userOccs, occupationFilterChange:true});
      }
      else{
        dispatch({ type: JOBS_FILTER, occupationCategoryFilterChange:true });
      }
      dispatch({type: FETCH_ALL_JOBS_COMPLETE, data:false});
    }
  } catch (e) {
    // TODO
    dispatch({
      type: FETCH_ALL_JOBS,
      jobs: []
    });
    console.log(e);
  }
};

  //this method probably needs a rework
  export const getSearchedCategoryAndJobs = (spec_occ) => async (dispatch, getState) => {
    const {
      user: {
        skills_names,
        contractDetails = {},
        rawdata: {
          latestAssessmentResult = {},
          qualifications,
          occupations,
          desiredOccupations,
          desiredExp,
          occupationsExtended,
          desiredOccupationsExtended,
          occupationsOriginal,
          desiredOccupationsOriginal,
        } = {},
        providerDetails={}
      },
      jobs: { rawJobCategories = {} },
    } = getState();
    let viewPermissions = {};
    if (providerDetails && providerDetails.viewPermissions) {
      viewPermissions = providerDetails.viewPermissions;
    }
    const userOccs = {occupations, occupationsOriginal, desiredOccupations, desiredOccupationsOriginal}
    var interests = latestAssessmentResult.interests;
    const { jobsType = 0 } = contractDetails;
    var jobs = _.cloneDeep(rawJobCategories);

    let payload = _.cloneDeep({
      latestAssessmentResult,
      desiredOccupations,
      occupationsExtended,
      occupations,
      desiredExp,
      qualifications,
      calculateQualifications: jobsType !== 0 ? true : false,
      skills_names,
      occupationsOriginal,
      desiredOccupationsOriginal,
    });

    for (const idx in payload.desiredExp) {
      if (payload.desiredExp[idx] === true){
        payload.desiredExp[idx] = false
        payload.occupations.push(payload.desiredOccupations[idx])
        payload.occupationsExtended.push(desiredOccupationsExtended[idx])
        payload.occupationsOriginal.push(desiredOccupationsOriginal[idx])
      }
    }
    payload.desiredOccupations = [spec_occ]
    payload.desiredOccupationsOriginal = [spec_occ]
    
    try {
      const new_jobs_cats = await API.post(
        config.API_NAME,
        "/jobs/query/all_occupations",
        {
          body: {
            data: payload,
          },
        }
      );


      let newSearchedCats = new_jobs_cats.jobs.desired_occupations

      jobs.searched_occupations.push(...newSearchedCats)
      jobs.searched_occupations = [...new Set(jobs.searched_occupations)]

      let db = await loadDB();      
      await clearTableDB(db, "jobCategories");
      await saveValuesToDB(db, jobs, "jobCategories");
      await dispatch({ type: FETCH_JOB_CATEGORIES, jobs});
      
      // Cache.setItem("jobCategories", jobs, { expires: moment().add(2, "days").valueOf()});
              
      new_jobs_cats.jobs.interest_occupations = []
      new_jobs_cats.jobs.past_occupations = []
      new_jobs_cats.jobs.skills_backpack_occupations = []
      new_jobs_cats.jobs.styles_occupations = []

      payload = {
        provider: providerDetails.sort,
        latestAssessmentResult: {
          interests,
        },
        ...new_jobs_cats.jobs,
        skills_names
      };
      const new_all_jobs = await API.post(config.API_NAME, "/jobs/query/all_jobs", {
        body: {
          data: payload,
        },
      });
      let filteredJobs = new_all_jobs.jobs.desired_occupations;
      if(viewPermissions && viewPermissions.jobsCheckbox){
        filteredJobs = filteredJobs.filter((item) => item.vacancy_flag);
      }
      else if(viewPermissions && viewPermissions.promotedJobsCheckbox){
        filteredJobs = filteredJobs.filter((item) => !item.vacancy_flag);
      }
      
      let idList = []
      let filteredList = []
      filteredJobs.forEach((item) => {
        if (!idList.includes(item.jobId)){
          idList.push(item.jobId)
          filteredList.push(item)
        }
      })
      
      jobs = await loadFromDB(db,"jobs");
      if (!jobs){
        jobs = {};
      }
      // this try catch is being caught in cypress and stopping the execution.
      try {
        jobs.searched_occupations.push(...filteredList)
        jobs.searched_occupations = [...new Set(jobs.searched_occupations)]
      }
      catch {
        jobs.searched_occupations = filteredList
      }
      dispatch(
        { 
          type: FETCH_INDIVIDUAL_JOBS, 
          jobs:
            { desired_occupations: [],
              interest_occupations: [],
              past_occupations: [],
              skills_backpack_occupations: [],
              styles_occupations: [],
              searched_occupations :jobs.searched_occupations,
            } 
          , jobsKey: "searched_occupations"
      });
      const preSeletedOcc = newSearchedCats[0].occupation
      if(preSeletedOcc){
        dispatch({ type: JOBS_FILTER, occupation:preSeletedOcc, isRemove:false, userOccs, occupationFilterChange:true});
      }
      else{
        dispatch({ type: JOBS_FILTER, occupationCategoryFilterChange:true });
      }
      await clearTableDB(db, "jobs");
      await saveValuesToDB(db, jobs, "jobs");
    }
    catch (e) {
      console.log(e);
    }
}

export const loadMoreJobs = (value) => async (
  dispatch,
  getState
) => {
  dispatch({ type: UPDATE_LOAD_MORE_COUNT, status: "loading", text: "Loading More Jobs..."});
  const {
    jobs: { rawJobCategories = {}, allJobs = [] },
    user: { rawdata: { latestAssessmentResult = {} } = {}, skills_names},
  } = getState();

  var occupations = _.cloneDeep(rawJobCategories);
  var interests = latestAssessmentResult.interests;

  let payload = {
    latestAssessmentResult: {
      interests,
    },
    page_occupations: []
  };

  let categoryKey = {}
  try {
    for (const [cat_key, cat_value] of Object.entries(occupations)) {
      for (const [occ_idx, occ_value] of cat_value.entries()) {
        if (value.includes(occ_value.occupation)){
          if (occ_value.page){
            occupations[cat_key][occ_idx].page += 1
          }
          else{
            occupations[cat_key][occ_idx].page = 2 //start at two,  one is essentially the first query
          }

          categoryKey[occ_value.occupation] = cat_key
          if(occ_value.alt_labels){
            for (const alt_labels of occ_value.alt_labels) {
              categoryKey[alt_labels] = cat_key
            }
          }
          payload.page_occupations.push(occupations[cat_key][occ_idx])
        }
      }
    }

    const {jobs} = await API.post(config.API_NAME, "/jobs/query/paginate_jobs", {
      body: {
        data: payload
      },
    });

    if (jobs){
      const combinedJobs = {
        desired_occupations: [],
        interest_occupations: [],
        past_occupations: [],
        skills_backpack_occupations: [],
        styles_occupations: [],
      }
      for (const individual_job of jobs.occupation_to_paginate) {
        combinedJobs[categoryKey[individual_job.occupation]].push(individual_job)
      }
  
      for (const [cat_key, cat_value] of Object.entries(combinedJobs)) {
        if (cat_value.length > 0){
          await dispatch(
            { 
              type: FETCH_INDIVIDUAL_JOBS, 
              jobs:
                { desired_occupations: [],
                  interest_occupations: [],
                  past_occupations: [],
                  skills_backpack_occupations: [],
                  styles_occupations: [],
                  searched_occupations :[],
                  [cat_key]: cat_value 
                },
              jobsKey: cat_key, loadMore:true
            });
        }
      
      }
      dispatch({ type: UPDATE_JOB_CATEGORIES, jobCategories:occupations }); // stores the new page numbers again
      dispatch({ type: ADD_FILTER, occupation:"", isRemove:false });
      dispatch({ type: UPDATE_LOAD_MORE_COUNT, status: "success", jobCount: allJobs});
    }
    else{
      dispatch({ type: UPDATE_LOAD_MORE_COUNT, status: "error", text: "Error Loading More Jobs"});
    }
  }
  catch (e) {
    console.log(e);
    dispatch({ type: UPDATE_LOAD_MORE_COUNT, status: "error", text: "Error Loading More Jobs"});
  }

  const delay = ms => new Promise(res => setTimeout(res, ms));
  await delay(4000)
  dispatch({ type: UPDATE_LOAD_MORE_COUNT});
}
    

export const fetchQualifications = (item = false) => async (
  dispatch,
  getState
) => {
  const {
    jobs: { qualNames = [] },
  } = getState();
  dispatch({ type: FETCH_QUALIFICATIONS, loading: true });
  try {
    let qualNameIsValid = false;
    let isItemValid = false;
    
    if (qualNames && qualNames.length > 0){
      qualNameIsValid = true;
    }
    if (item && item.qualification_name){
      isItemValid = true;
    }
    // 
    if (isItemValid || qualNameIsValid ){
      if (!Cache.getItem("qualsV2")) {
        const { jobs } = await API.post(
          config.API_NAME,
          "/jobs/query/qualification",
          {
            body: {
              data: !item ? qualNames : item.qualification_name,
            },
          }
        );
        
        await dispatch({ type: FETCH_QUALIFICATIONS, jobs, loading: false  });
        dispatch({ type: JOBS_FILTER, occupationCategoryFilterChange:true });

        if (jobs && jobs.length >= 1) {
          Cache.setItem("qualsV2", true, {
            expires: moment().add(30, "m").valueOf(),
          });
          let db = await loadDB();
          await clearTableDB(db, "quals");
          await saveValuesToDB(db, jobs, "quals");
        }
      } else {
        let db = await loadDB();
        let quals = await loadFromDB(db,"quals", "arr");
  
        await dispatch({
          type: FETCH_QUALIFICATIONS,
          jobs: quals,
          loading: false
        });
        dispatch({ type: JOBS_FILTER, occupationCategoryFilterChange:true });
      }
    } else {
      dispatch({ type: FETCH_QUALIFICATIONS, jobs: [], loading: false});
    }
    dispatch(fetchCoins())
  } catch (e) {
    // TODO
  }
};

export const fetchCentres = (centreIds) => async (dispatch, getState) => {
  const {
    user: { rawdata: { latestAssessmentResult = {} } = {} },
  } = getState();
  try {
    const { jobs } = await API.post(
      config.API_NAME,
      "/jobs/query/centre_query",
      {
        body: {
          data: {
            latestAssessmentResult: {
              interests: latestAssessmentResult.interests,
            },
            centre_list_ids: centreIds,
          },
        },
      }
    );
    return jobs
  } catch (e) {
    // TODO
    console.log(e);
    return false
  }
};

export const addOccupationFilter = (occupation, isRemove = false, reset = false) => async (
  dispatch,
  getState
) => {
  const {
    user: {
      rawdata: {
        occupations,
        occupationsOriginal,
        desiredOccupations,
        desiredOccupationsOriginal,
      } = {},
      internalMobilityProvider
    },
    jobs:{
      allJobCategories = []
    }
  } = getState();

  //If tying to filter a occ with no jobs, do job query
  const foundJobCat = allJobCategories.find((item) => item.occupation === occupation)
  if(!isRemove && foundJobCat && !foundJobCat.jobsCount > 0 && !internalMobilityProvider){
    dispatch(loadOccpationJobs(foundJobCat.parent_occupation ? foundJobCat.parent_occupation : occupation, foundJobCat.occKey));
  }
  else{
    const userOccs = {occupations, occupationsOriginal, desiredOccupations, desiredOccupationsOriginal}
    if(Array.isArray(occupation)){
      for (const occ of occupation) {
        dispatch({ type: JOBS_FILTER, occupation:occ, isRemove, userOccs, occupationFilterChange:true, occupationFilterReset:reset});
      }
    }
    else{
      dispatch({ type: JOBS_FILTER, occupation, isRemove, userOccs, occupationFilterChange:true, occupationFilterReset:reset});
    }
  }
};

export const resetFilter = () => async (
  dispatch,
  getState
) => {

  // dispatch({ type: OCC_FILTER, filter: [
  //   { name: "Preferred", show: true },
  //   { name: "Previous", show: true },
  //   { name: "Skills", show: true },
  //   { name: "Styles", show: true },
  //   { name: "Interest", show: true },
  //   { name: "Searched", show: true },
  // ] });

};

export const filterQualsByOccupation = (category) => async (
  dispatch,
  getState
) => {
  Cache.setItem("paginationConfig", {
    recommended: 1,
    qualifications: 1,
    applied: 1,
    saved: 1,
  });
  Cache.setItem("sm_current_tab", 1);
};

export const filterJobsByOccupation = (occupation) => async (
  dispatch,
  getState
) => {
  Cache.setItem("paginationConfig", {
    recommended: 1,
    qualifications: 1,
    applied: 1,
    saved: 1,
  });

  Cache.setItem("sm_current_tab", 0);
};

export const fetchSkillsInfo = () => async (dispatch, getState) => {
  const {
    user: { rawdata: { occupations, desiredOccupations, occupationSkills } = {} },
  } = getState();

  try {
    let payload = {
      skills:[]
    };

    occupationSkills.forEach((skill, i) => {
      payload.skills.push(...skill.skills.map((individual_skill) => individual_skill.name))
      if(skill.optionalSkills && skill.optionalSkills.length > 0){
        payload.skills.push(...skill.optionalSkills.map((individual_skill) => individual_skill.name))
      }
      if(skill.deletedSkills && skill.deletedSkills.length > 0){
        payload.skills.push(...skill.deletedSkills.map((individual_skill) => individual_skill.name))
      }
      
    })

    const { data, typeData } = await API.post(
      config.API_NAME,
      "/data/skills/description",
      {
        body: payload
      }
    );
    await Promise.all([dispatch({ type: FETCH_SKILLS_LIST, skills: data, skillTypes: typeData })]);
  } catch (e) {
    console.log(e)
  }
};

export const setFavouriteJob = (isFavourited, job) => async (dispatch) => {
  try {
    var { jobTitle, occupation, tier_one_category, qual_name, rangeKey, isInvited } = job;
    var isQualification = false;

    if (qual_name) {
      isQualification = true;
      jobTitle = qual_name;
      rangeKey = job.qual_awarding_org + "#" + occupation + "#" + job.jobId;
    }

    if (isFavourited) {
      // NO Response implemented yet
      /* eslint-disable*/
      const { jobs } = await API.post(config.API_NAME, "/jobitems/saved", {
      /* eslint-enable*/
        body: {
          rangeKey: rangeKey,
          jobitem: job,
          occupation: occupation,
          tier_one_category: tier_one_category,
          isQualification,
        },
      });
      dispatch(
        recordJobClick(
          "unfav",
          jobTitle,
          occupation,
          tier_one_category,
          isQualification
        )
      );
    } else {
      // NO Response implemented yet
      /* eslint-disable */
      if (isInvited) {
        const { jobs } = await API.del(config.API_NAME, "/jobitems/invited", {
          /* eslint-enable */
            body: {
              rangeKey: rangeKey,
              jobitem: job,
              isQualification,
            },
          });
      } else {
        await API.del(config.API_NAME, "/jobitems/saved", {
            /* eslint-enable */
              body: {
                rangeKey: rangeKey,
                jobitem: job,
                isQualification,
              },
            });
        }
      dispatch(
        recordJobClick(
          "fav",
          jobTitle,
          occupation,
          tier_one_category,
          isQualification
        )
      );
    }
    job.savedTimestamp = Date.now();
    dispatch({
      type: SET_FAVOURITE_JOB,
      payload: { favourited: isFavourited, job },
    });
    dispatch(fetchCoins())
  } catch (e) {
    console.log(e);
    // TODO
  }
};

export const getFavouriteJobs = () => async (dispatch) => {
  const { saved, invited } = await API.get(config.API_NAME, "/jobitems", {});
  dispatch({ type: FETCH_FAVOURITED_JOBS, favourited: saved, invited });
};

export const getAppliedJobs = () => async (dispatch) => {
  const { applied } = await API.get(config.API_NAME, "/jobitems", {});
  dispatch({ type: FETCH_APPLIED_JOBS, jobs: applied });
};

export const setJobItemRead = (item) => async (dispatch, getState) => {
  const {
    user: {
      rawdata: {
        userID
      } = {},
    },
  } = getState();

  await API.post(config.API_NAME, "/jobitems/set/read", {
      body: {
        userID: userID,
        itemSort: item.itemSort,
      },
    });
  dispatch({ type: MARK_ITEM_READ, itemSort: item.itemSort});
  dispatch(fetchCoins())
};

export const vacancyApply = (job) => async (dispatch, getState) => {
  const {
    user: {
      rawdata: {
        userID,
        firstName,
        lastName,
        phoneNumber,
        email
      } = {},
    },
  } = getState();
  try {
    let body = {
      jobId: job.jobId,
      jobSort: job.rangeKey,
      userId : userID,
      firstName: firstName,  
      lastName: lastName,
      phoneNumber : phoneNumber,
      user_email: email
    };
    /* eslint-disable */
    const { jobs } = await API.post(config.API_NAME, "/jobitems/applied/vacancy", {
      body: body,
    });
    /* eslint-enable */
    dispatch(fetchCoins())
  } catch (error) {
    console.log(error);
  }
};

export const applyForJob = (job, recreate, verified, applicationDelay) => async (dispatch) => {
  try {
    var { jobTitle, occupation, tier_one_category, qual_name, rangeKey } = job;
    var isQualification = false;

    if (qual_name) {
      isQualification = true;
      jobTitle = qual_name;
      rangeKey = job.qual_awarding_org + "#" + occupation + "#" + job.jobId;
    }
    // NO Response implemented yet
    /* eslint-disable */
    const { jobs } = await API.post(config.API_NAME, "/jobitems/applied", {
    /* eslint-enable */
      body: {
        rangeKey: rangeKey,
        occupation: occupation,
        jobitem: job,
        tier_one_category: tier_one_category,
        isQualification,
        recreate: recreate,
        verified: verified,
        applicationDelay: applicationDelay
      },
    });
    if (!recreate){
      dispatch(
        recordJobClick(
          "applied",
          jobTitle,
          occupation,
          tier_one_category,
          isQualification
        )
      );
    }
    dispatch(fetchCoins())
  } catch (error) {
    console.log(error);
    // TODO
  }
  // just re-fetching applied jobs, as for notifications they need stuff that the backend provides(which the reducer wont have)
  await dispatch(getAppliedJobs());
  await dispatch(getFavouriteJobs());
  dispatch({ type: JOBS_FILTER, occupationCategoryFilterChange:true });
  // dispatch({ type: APPLY_FOR_A_JOB, job: job , updatePosition: true });
};

export const interviewForJob = (job, recreate, verified) => async (dispatch) => {
  try {
    var { jobTitle, occupation, tier_one_category, qual_name, rangeKey } = job;
    var isQualification = false;

    if (qual_name) {
      isQualification = true;
      jobTitle = qual_name;
      rangeKey = job.qual_awarding_org + "#" + occupation + "#" + job.jobId;
    }
    // NO Response implemented yet
    /* eslint-disable */
    const { jobs } = await API.post(config.API_NAME, "/jobitems/interviewed", {
    /* eslint-enable */
      body: {
        rangeKey: rangeKey,
        jobitem: job,
        isQualification,
        recreate: recreate,
        verified: verified
      },
    });
    dispatch(
      recordJobClick(
        "applied",
        jobTitle,
        occupation,
        tier_one_category,
        isQualification
      )
    );
    dispatch(fetchCoins())
  } catch (error) {
    console.log(error);
    // TODO
  }
  // job.savedTimestamp = Date.now();
  job.interview = true;
  dispatch({ type: APPLY_FOR_A_JOB_UPDATE, job: job, updatePosition: true });
};



export const startedJob = (job, recreate, verified) => async (dispatch) => {
  try {
    var { jobTitle, occupation, tier_one_category, qual_name, rangeKey } = job;
    var isQualification = false;

    if (qual_name) {
      isQualification = true;
      jobTitle = qual_name;
      rangeKey = job.qual_awarding_org + "#" + occupation + "#" + job.jobId;
    }
    // NO Response implemented yet
    /* eslint-disable */
    const { jobs } = await API.post(config.API_NAME, "/jobitems/started", {
    /* eslint-enable */
      body: {
        rangeKey: rangeKey,
        jobitem: job,
        isQualification,
        recreate: recreate,
        verified: verified
      },
    });
    dispatch(
      recordJobClick(
        "applied",
        jobTitle,
        occupation,
        tier_one_category,
        isQualification
      )
    );
    dispatch(fetchCoins())
  } catch (error) {
    console.log(error);
    // TODO
  }
  // job.savedTimestamp = Date.now();
  job.interview = true;
  job.started = true;
  dispatch({ type: APPLY_FOR_A_JOB_UPDATE, job: job, updatePosition:true });
};



export const didntGetJob = (job, recreate, verified) => async (dispatch) => {
  try {
    var { jobTitle, occupation, tier_one_category, qual_name, rangeKey } = job;
    var isQualification = false;

    if (qual_name) {
      isQualification = true;
      jobTitle = qual_name;
      rangeKey = job.qual_awarding_org + "#" + occupation + "#" + job.jobId;
    }
    // NO Response implemented yet
    /* eslint-disable */
    const { jobs } = await API.post(config.API_NAME, "/jobitems/unsuccessful", {
    /* eslint-enable */
      body: {
        rangeKey: rangeKey,
        jobitem: job,
        isQualification,
        recreate: recreate,
        verified: verified
      },
    });
    dispatch(
      recordJobClick(
        "applied",
        jobTitle,
        occupation,
        tier_one_category,
        isQualification
      )
    );
    dispatch(fetchCoins())
  } catch (error) {
    console.log(error);
    // TODO
  }
  // job.savedTimestamp = Date.now();
  job.unsuccessful = true;
  dispatch({ type: APPLY_FOR_A_JOB_UPDATE, job: job, updatePosition: true });
};


export const deleteAppliedJob = (job) => async (dispatch) => {
  try {
    var { jobTitle, occupation, tier_one_category, qual_name, rangeKey, interview } = job;
    var isQualification = false;

    if (qual_name) {
      isQualification = true;
      jobTitle = qual_name;
      rangeKey = job.qual_awarding_org + "#" + occupation + "#" + job.jobId;
    }
    // NO Response implemented yet
    /* eslint-disable */
    if (interview){
      await API.del(config.API_NAME, "/jobitems/interviewed", {
        /* eslint-enable */
          body: {
            rangeKey: rangeKey,
            jobitem: job,
            isQualification,
          },
        });
    }
    else{
      await API.del(config.API_NAME, "/jobitems/applied", {
        /* eslint-enable */
          body: {
            rangeKey: rangeKey,
            jobitem: job,
            isQualification,
          },
        });
    }
  } catch (error) {
    console.log(error);
    // TODO
  }
  await dispatch({ type: APPLY_FOR_A_JOB_UPDATE, job: job, deleteJob: true });
  dispatch({ type: JOBS_FILTER, occupationCategoryFilterChange:true });
};





export const getIndeedDescription = (id) => async (dispatch) => {
  try {
    // NO Response implemented yet
    /* eslint-disable */
    const { jobs } = await API.post(config.API_NAME, "/jobs/query/indeed_job_description", {
    /* eslint-enable */
      body: {
        data: {
          "job_id": id
        }
      },
    });
    if(jobs && jobs){
      return jobs
    }

  } catch (error) {
    console.log(error);
    // TODO
  }
};


export const getReedDescription = (id) => async (dispatch) => {
  try {
    // NO Response implemented yet
    /* eslint-disable */
    const { jobs } = await API.post(config.API_NAME, "/jobs/query/reed_job_details", {
    /* eslint-enable */
      body: {
          "id": id
      },
    });
    if(jobs && jobs.jobDescription){
      // console.log("TRIGGERING DISPATCH")
      //dispatch({ type: UPDATE_FULL_DESCRIPTION, id: id, description: jobs.jobDescription});
      return jobs.jobDescription
    }

  } catch (error) {
    console.log(error);
    // TODO
  }
};

export const loadedContentDashboard = () => async (dispatch) => {
  try {

    dispatch({ type: LOADED_CONTENT_DASHBOARD, loaded: true });

  } catch (e) {
    console.log(e);
  }
}


export const getOptionalSkillCheck = (skills) => async (dispatch) => {

  let promises = [];
  _.each(skills , (i) => {
    const promise = new Promise(async (resolve, reject) => { 
      let {jobs} = await API.post(config.API_NAME, "/jobs/query/check_optional_match", {
          body: {
            data: {
              "occupation": i.occupation,
              "skills": i.skills
            }
          },
        });
        jobs.occupation = i.occupation;
        resolve(jobs);
    });

    promises.push(promise);
  });

  Promise.all(promises).then((values) => {
    dispatch({type: OPTIONAL_SKILL_CHECK, values: values});
  });
}