import { API } from "aws-amplify";
import {
  FETCH_USER,
  USER_CLICK_HELLO,
  FETCH_USER_PROFILE_URL,
  OPEN_UPDATE_SKILLS,
  CLOSE_UPDATE_SKILLS,
  USER_CLICK_PROFILE_EDIT,
  USER_PROFILE_EDIT_DONE,
  RESET_JOBS,
  MARK_SKILLS_UPDATED,
  BLACKLIST_OCC,
  SET_BLACKLIST,
  ADD_NEW_SKILL,
  UPDATE_CHAT_SKILLS,
  SET_NOTIFICATION,
  SET_NOTIFICATION_AMOUNT,
  SET_USER_PERMISSIONS,
  SET_SKILLS,
  UPDATE_DEMOGRAPHICS,
  UPDATE_DEMOGRAPHICS_QUESTIONS,
  OPEN_DEMOGRAPHICS,
  FETCH_INTERNAL_MOBILITY_ROLES,
  HIDE_AA_HELP_TEXT,
  JOBS_FILTER,
  SET_COMPLETED_SKILLS_ASSESSMENTS,
  SET_PROJECTED_SKILS,
  SET_VALIDATION_REQUESTS,
  SET_SKILL_NOTIFICATION
} from "./types";
import { logout, validateUserSession } from "./auth_actions";
import {fetchCoins} from "./reward_actions";
import { updateEndpoint, recordSkillUpdate } from "./analytics_actions";
import { fetchSkillsInfo, preLoadJobsSearch, toggleLoading, addOccupationFilter} from "./job_actions";
import { resetMessages, sendMessage } from "./chat_actions";
import _ from "lodash";
import config from "../config/";
import { Logger, Cache, Storage } from "aws-amplify";
const logger = new Logger("USER.ACTIONS", "INFO");

// Cognito - Auth.signIn()
export function fetchUser(onlyUser = false) {
  return async function (dispatch, getState) {

    try {
      let data = await API.get(config.API_NAME, `/users`).catch(() => {
        data = false;
        dispatch(logout());
      });

      if (data === false){
        return false;
      }
      //console.log(data);
      if (data.userID) {
        await Promise.all([dispatch({ type: FETCH_USER, user: data })]);
        dispatch({ type: SET_BLACKLIST, newBlackList:data.occupationBlacklist });
        if (data.assessmentCompleted){
          await Promise.all([dispatch(fetchSkillsInfo())]);
        }
        if (!onlyUser) {
          dispatch(fetchProfileURL());
          dispatch(updateEndpoint());
          dispatch(getSkillAssessmentCompleted());
          dispatch(getSkillValidationRequests());
          dispatch(getProjectedSkills());
        }

      } else {
        dispatch({ type: FETCH_USER, user: {} });
      }
    } catch (e) {
      logger.error("fetchUserDetails():", e);
      dispatch(logout());
      //dispatch({ type: FETCH_USER, user: {} });
    }
  };
}

export function fetchProfileURL() {
  return async function (dispatch) {
    var profileURL;
    try {
      profileURL = await Storage.get("profile.png", { level: "private" });
      dispatch({ type: FETCH_USER_PROFILE_URL, profileURL });
    } catch (e) {
      dispatch({ type: FETCH_USER_PROFILE_URL, profileURL: false });
      console.log(e);
    }
  };
}

export function clickHello() {
  return async function (dispatch) {
    Cache.setItem("clickedHello", true);
    dispatch({ type: USER_CLICK_HELLO });
  };
}

export function clickProfileEdit() {
  return async function (dispatch) {
    dispatch({ type: USER_CLICK_PROFILE_EDIT });
  };
}
export function doneProfileEdit() {
  return async function (dispatch) {
    dispatch({ type: USER_PROFILE_EDIT_DONE });
    dispatch({ type: RESET_JOBS });
    dispatch(preLoadJobsSearch())
  };
}

export function openUpdateSkills(key, skill) {
  return async function (dispatch) {
    dispatch({ type: OPEN_UPDATE_SKILLS, occkey: key, highlightSkill:skill });
  };
}
export function closeUpdateSkills(skipReset) {
  return async function (dispatch, getState) {
    const {
      user : {
        skillWasEdited
      }
    } = getState();

    await dispatch(fetchUser(true));
    dispatch({ type: CLOSE_UPDATE_SKILLS });
    dispatch(fetchCoins())
    //only if changed
    if (!skipReset && skillWasEdited && skillWasEdited === true){
      dispatch({ type: RESET_JOBS });
      dispatch(preLoadJobsSearch())
    }
    
  };
}


export function sendFeedback(description, id, _type) {
  return async function (dispatch) {
    await API.post(config.API_NAME, `/users/feedback`, {
      body: {
        description: description,
        type: _type,
        id: id
      }
    });
  }
}

export function saveSkills(occupation, skills, optionalSkills, deletedSkills, updatedSkills) {
  return async function (dispatch) {
    // console.log("save skills", occupation, skills);
    dispatch(recordSkillUpdate(occupation));
    try {
      let occ = await API.put(config.API_NAME, `/users/skills`, {
        body: {
          occupation: occupation,
          skills: skills,
          optionalSkills: optionalSkills ? optionalSkills : [],
          deletedSkills: deletedSkills ? deletedSkills : [],
          updatedSkills: updatedSkills ? updatedSkills : []  //list of updatedSkills for coins + experties check
        },
      });
      //console.log(data);
      if (occ.data && occ.data.Attributes && occ.data.Attributes.occupationSkills){
        dispatch({ type: SET_SKILLS, occupationSkills: occ.data.Attributes.occupationSkills });
      }

    } catch (e) {
      logger.error("saveSkills ():", e);
    }
    


    dispatch({ type: MARK_SKILLS_UPDATED});
    // dispatch(fetchCoins())
  };
}

export function addSkillsFromProfile(skills) {
  return async function (dispatch, getState) {
    try {
      const { user: { occupationSkills }} = getState()
      let newOccupationSkills = occupationSkills
      let skillObjIdx = null
      let newSkillList = skills.map((item) => {return {"name": item, "level": 1}})

      occupationSkills.forEach(function (item, index) {
        if (item.occupation === "Manually Added: Skills"){
          skillObjIdx = index
        }
      });
      
      if(skillObjIdx){
        newOccupationSkills[skillObjIdx].skills.push(...newSkillList)
        await dispatch(saveSkills(newOccupationSkills[skillObjIdx].occupation, newOccupationSkills[skillObjIdx].skills, newOccupationSkills[skillObjIdx].optionalSkills, newOccupationSkills[skillObjIdx].deletedSkills, skills))
      }
      else{
        skillObjIdx = newOccupationSkills.length
        newOccupationSkills.push({'occupation': "Manually Added: Skills", 'expertise': "Beginner", 'skills': newSkillList, 'optionalSkills': [], 'deletedSkills':[]})
        await API.put(config.API_NAME, `/users/addSkill`, {body: {...newOccupationSkills[skillObjIdx], isNewSkill: true}});
        dispatch({ type: MARK_SKILLS_UPDATED});
      }
      dispatch({ type: ADD_NEW_SKILL, newOccupationSkills, skillObjIdx });
    } catch (e) {
      logger.error("addSkillsFromProfile():", e);
    }
  }
}

export function fetchSkillDetails(skill, skillList=[], skillCategory) {
  return async function (dispatch, getState) {
    const { user: { occupationSkills }} = getState()
    let newOccupationSkills = occupationSkills
    let skillObjIdx = null
    let newSkillList = [{"name": skill, "level": 1}]

    try {
      if (skillList && skillList.length){
        skillList.forEach((root_item) => {
          if (root_item.name.includes("(end)")){
            root_item.bottomLevelSkills.forEach((bottom_skill) => {
              newSkillList.push({"name": bottom_skill, "level": 1})
            })
          }
          else{
            newSkillList.push({"name": root_item.name, "level": 1})
          }
        })
      }
      newSkillList = _.uniqBy(newSkillList, 'name')
      occupationSkills.forEach(function (item, index) {
        if (item.occupation === "Manually Added: " + skillCategory){
          skillObjIdx = index
        }
      });

      if (skillObjIdx){
        newOccupationSkills[skillObjIdx].skills.push(...newSkillList)
        dispatch(saveSkills(newOccupationSkills[skillObjIdx].occupation, newOccupationSkills[skillObjIdx].skills, newOccupationSkills[skillObjIdx].optionalSkills, newOccupationSkills[skillObjIdx].deletedSkills, [skill]))
      }
      else{
        skillObjIdx = newOccupationSkills.length
        newOccupationSkills.push({'occupation': "Manually Added: " + skillCategory, 'expertise': "Beginner", 'skills': newSkillList, 'optionalSkills': [], 'deletedSkills':[]})
        API.put(config.API_NAME, `/users/addSkill`, {body: {...newOccupationSkills[skillObjIdx], isNewSkill: true}});
      }
      dispatch({ type: ADD_NEW_SKILL, newOccupationSkills, skillObjIdx });
      // dispatch(fetchCoins())
      await dispatch({ type: UPDATE_CHAT_SKILLS, newOccupationSkills, skillObjIdx });
    } catch (e) {
      logger.error("fetchSkillDetails():", e);
    }
  }
}

export const updateJobMatches = () => async (
  dispatch,
  getState
) => {

  
  let _state = getState();
 
  let isCached = Cache.getItem("interestStyles");

  if (isCached){
    return true;
  }

  let matchesStyles,matchesInterest,bestBackpack;
  if (_state.jobs && _state.jobs.rawJobCategories){
    matchesStyles = _state.jobs.rawJobCategories.styles_occupations;
    matchesInterest = _state.jobs.rawJobCategories.interest_occupations;
    bestBackpack = _state.jobs.rawJobCategories.skills_backpack_occupations;
  } else {
    return false;
  }

  if (matchesStyles && matchesInterest && bestBackpack){
    await API.put(config.API_NAME, `/users/styles`, {
      body: {
        jobStyle: matchesStyles,
        interestStyle: matchesInterest,
        skillPackBack: bestBackpack
      }
    });
    Cache.setItem("interestStyles", true);

  }
}

export const updateReverseMineIndexes = () => async (
  dispatch,
  getState
) => {
  const { 
    user: {
      provider,
      occupationSkills
    },
    jobs : {
      rawJobCategories
    }} = getState();
  if (rawJobCategories){
    let occupationSkillsFakeList = ""

    for (const skillType of occupationSkills) {
      for(const skillObject of skillType.skills){
        occupationSkillsFakeList = occupationSkillsFakeList + "#" + skillObject.name + "#"
      }
    }

    await API.put(config.API_NAME, `/users/updateRevMineIdxs`, {
      body: {
        provider: provider,
        mineable_skills: occupationSkillsFakeList
      }
    });
  }
}


export const updateOccupationBlacklist = (occupation, isRemove=false) => async (
  dispatch,
  getState
) => {
  dispatch(toggleLoading(true));
  if (occupation){
    await dispatch({ type: BLACKLIST_OCC, occupation:occupation, isRemove:isRemove });
  }
  else{
    await dispatch({ type: SET_BLACKLIST });
    Cache.removeItem("jobs")
  }
  
  const { jobs : { occupationBlacklist } } = getState();
  try {
    await API.put(config.API_NAME, `/users/occupationBlacklist`, {
      body: {
        occupationBlacklist: occupationBlacklist
      },
    });
  } catch (e) {
    logger.error("updateOccupationBlacklist ():", e);
  }
  if (occupation){
    dispatch(addOccupationFilter(occupation, isRemove ? false : true));
  }
  else{
    dispatch({ type: JOBS_FILTER, occupationCategoryFilterChange:true });
  }
  dispatch(toggleLoading(false));
  
};

export function deleteAccount() {
  return async function (dispatch) {
    try {
      await API.del(config.API_NAME, `/account`);
      dispatch(validateUserSession()); //console.log(data);
      window.location.replace("https://www.skillsminer.ai");
    } catch (e) {
      logger.error("deleteAccount ():", e);
    }
  };
}

export function enableNotification(enabled) {
  return async function (dispatch) {
    try {
      await API.post(config.API_NAME, `/account/notifications`, {
        body: {
          enabled: enabled,
        },
      });
      dispatch(fetchUser(true)); //console.log(data);
    } catch (e) {
      logger.error("enableNotification ():", e);
    }
  };
}

export function enableCookies(enabled) {
  return async function (dispatch) {
    try {
      await API.post(config.API_NAME, `/account/cookie`, {
        body: {
          enabled: enabled,
        },
      });
      dispatch({type: SET_USER_PERMISSIONS, enabled: enabled}); //console.log(data);
    } catch (e) {
      logger.error("enableNotification ():", e);
    }
  };
}


export function setNotification(goto) {
  return async function (dispatch){
    dispatch({type: SET_NOTIFICATION,
      goto: goto
    });
  }
}

export function setNotificationAmount(amount) {
  return async function (dispatch){
    dispatch({type: SET_NOTIFICATION_AMOUNT,
      notificationAmount: amount
    });
  }
}

export function submitDemographics(demoData) {
  return async function (dispatch, getState) {
    try {
      const { auth : { userID }, user: { showChat } } = getState();
      const { data } = await API.put(config.API_NAME, `/users/demographics`, {
        body: demoData,
      });
      if(data){
        await dispatch({type: UPDATE_DEMOGRAPHICS, demoData: data, showPage: false  });
        if(showChat){
          await Promise.all([dispatch(resetMessages())])
          // console.log("submitDemographics")
          setTimeout(
            async () =>
              await Promise.all([dispatch(sendMessage([{_id: 1, text: "BEGIN_CHAT",  hidden: true, user: {_id: userID}}]))])
            ,1000
          );
          
        }
      }
    } catch (e) {
      logger.error("submitDemographics() error :", e);
    }
  };
}

export function showDemographics() {
  return async function (dispatch, getState) {
    const { user: { internalMobilityResponses, internalMobilityProvider } } = getState();
    dispatch({type: UPDATE_DEMOGRAPHICS, demoData: {}, showPage: true, IMdata:internalMobilityResponses});
    if(internalMobilityProvider){
      dispatch(getinternalMobilityDemographics());
    }
  };
}

export function setHideAAHelpText(storeChoice) {
  return async function (dispatch) {
    if(storeChoice){
      try {
        await API.post(config.API_NAME, `/user/applicationAssistant/hideHelp`)
      } catch (e) {
        logger.error("setHideAAHelpText ():", e);
      }
    }
    dispatch({type: HIDE_AA_HELP_TEXT });
  };
}

export function getinternalMobilityDemographics() {
  return async function (dispatch, getState) {
    try {
      // console.log("getinternalMobilityDemographics")
      const data = await API.get(config.API_NAME, `/users/internalMobilityDemographics`, {});
      if(data && data.success){
        await dispatch({type: UPDATE_DEMOGRAPHICS_QUESTIONS, data: data.data.questions, roles: data.roles, departments: data.departments, lineManagers: data.lineManagers});
      }
    } catch (e) {
      logger.error("getinternalMobilityDemographics() error :", e);
    }
  };
}


export function reOpenDemographics() {
  return async function (dispatch, getState) {
    try {
      await dispatch({type: OPEN_DEMOGRAPHICS});
    } catch (e) {
      logger.error("reOpenDemographics() error :", e);
    }
  };
}


export function getinternalMobilityRoles() {
  return async function (dispatch, getState) {
    try {
      const {
        user: { rawdata }
      } = getState();
      // console.log("getinternalMobilityDemographics")
      const data = await API.get(config.API_NAME, `/users/internalMobilityRoles`, {});
      if(data && data.success){
        const whiteListedOccs = rawdata && rawdata.desiredOccupations ? rawdata.desiredOccupations : []
        const filteredRoles = data.data.filter((item) => item.disabled && !whiteListedOccs.includes(item) ? false : true)
        await dispatch({type: FETCH_INTERNAL_MOBILITY_ROLES, data: filteredRoles});
        return filteredRoles
      }
    } catch (e) {
      logger.error("getinternalMobilityDemographics() error :", e);
    }
  };
}

export function submitDemographicsInternalMobility({questions, department, role, lineManager}) {
  return async function (dispatch, getState) {
    try {
      // console.log(department, role, lineManager)
      const { auth : { userID }, user: { showChat } } = getState();
      const { data } = await API.put(config.API_NAME, `/users/internalMobilityDemographics`, {
        body: {
          questions: questions,
          department: department, 
          role: role,
          lineManager: lineManager
        },
      });
      if(data){
        let showPage = false;
        let holdUser = false;

        if(data.internalMobilityRoleVerified === "n"){
          holdUser = true;
          showPage = true
        }
        await dispatch({
          type: UPDATE_DEMOGRAPHICS, 
          demoData: {
            internalMobilityRole: data.internalMobilityRole,
            internalMobilityRoleVerified: data.internalMobilityRoleVerified,
            internalMobilityDepartment: data.internalMobilityDepartment,
            internalMobilityDepartmentVerified: data.internalMobilityDepartmentVerified,
            internalMobilityRoleDetails: data.internalMobilityRoleDetails,
            internalMobilityLineManager: data.internalMobilityLineManager,
            internalMobilityLineManagerVerified: data.internalMobilityLineManagerVerified,
            internalMobilityLineManagerDetails: data.internalMobilityLineManagerDetails,
          }, 
          showPage: showPage, 
          holdUser: holdUser,
          IMdata: data.internalMobilityResponses, 
        });
        if(showChat){
          await Promise.all([dispatch(resetMessages())])
          // console.log("submitDemographics")
          setTimeout(
            async () =>
              await Promise.all([dispatch(sendMessage([{_id: 1, text: "BEGIN_CHAT",  hidden: true, user: {_id: userID}}]))])
            ,1000
          );
          
        }
      }
    } catch (e) {
      logger.error("submitDemographics() error :", e);
    }
  };
}

export function getSkillAssessmentCompleted() {
  return async function (dispatch, getState) {
    try {
      const data = await API.get(config.API_NAME, `/users/getSkillsAssessmentCompleted`);
      if(data.success && data.data){
        dispatch({type: SET_COMPLETED_SKILLS_ASSESSMENTS, data: data.data});
      }
      else{
        dispatch({type: SET_COMPLETED_SKILLS_ASSESSMENTS, data: {}});
      }
    } catch (e) {
      logger.error("getSkillAssessmentCompleted() error :", e);
    }
  };
}

export function setSkillsAssessmentCompleted(assessment_data) {
  return async function (dispatch, getState) {
    try {
      const data = await API.post(config.API_NAME, `/users/setSkillsAssessmentCompleted`, {
        body: {
          skill_name: assessment_data.skill_name,
          assessment_version: assessment_data.assessment_version,
          assessment_data: assessment_data.questions
        },
      });
      if(data.success && data.data){
        dispatch({type: SET_COMPLETED_SKILLS_ASSESSMENTS, data: data.data});
        dispatch(setSkillValidationRequests(assessment_data.skill_name));
      }
      else{
        dispatch({type: SET_COMPLETED_SKILLS_ASSESSMENTS, data: {}});
      }
    } catch (e) {
      logger.error("setSkillsAssessmentCompleted() error :", e);
    }
  };
}


export function getSkillValidationRequests() {
  return async function (dispatch, getState) {
    try {
      const data = await API.post(config.API_NAME, `/users/skillvalidations/get`, {body:{}});
      if(data.success){
        dispatch({type: SET_VALIDATION_REQUESTS, completed: data.completed, requests:data.requests});
      }
      else{
        dispatch({type: SET_VALIDATION_REQUESTS, completed: [], requests:[]});
      }
    } catch (e) {
      logger.error("getSkillValidationRequests() error :", e);
    }
  };
}

export function setSkillValidationRequests(skill) {
  return async function (dispatch, getState) {
    try {
      const data = await API.post(config.API_NAME, `/users/skillsvalidations/set`, {
        body:{
          skill: skill
        }
      });
      if(data.success){
        dispatch({type: SET_VALIDATION_REQUESTS, completed: data.completed, requests:data.requests});
      }
      else{
        dispatch({type: SET_VALIDATION_REQUESTS, completed: [], requests:[]});
      }
    } catch (e) {
      logger.error("setSkillValidationRequests() error :", e);
    }
  };
}

export function getProjectedSkills() {
  return async function (dispatch, getState) {
    try {
      const data = await API.post(config.API_NAME, `/users/projectedskills/get`, {body:{}});
      if(data.success){
        dispatch({type: SET_PROJECTED_SKILS, read: data.read, unread:data.unread});
      }
      else{
        dispatch({type: SET_PROJECTED_SKILS, read: [], unread:[]});
      }
    } catch (e) {
      logger.error("getProjectedSkills() error :", e);
    }
  };
}

export function setProjectedSkills(skill) {
  return async function (dispatch, getState) {
    const { user: { occupationSkills=[] } } = getState();
    try {
      let targetOcc = false
      for (const occSkill of occupationSkills) {
        if(occSkill.skills.find((item) => item.name === skill) || (occSkill.optionalSkills && occSkill.optionalSkills.find((item) => item.name === skill)) ||(occSkill.deletedSkills && occSkill.deletedSkills.find((item) => item.name === skill))){
          targetOcc = occSkill.occupation
          break
        }
      }
      if(targetOcc){
        dispatch({type: SET_SKILL_NOTIFICATION, targetOcc: targetOcc, targetSkill:skill});
        dispatch(openUpdateSkills(targetOcc, skill))
      }

      const data = await API.post(config.API_NAME, `/users/projectedskills/set`, {
        body:{
          skill: skill
        }
      });
      if(data.success){
        dispatch({type: SET_PROJECTED_SKILS, read: data.read, unread:data.unread});
      }
      else{
        dispatch({type: SET_PROJECTED_SKILS, read: [], unread:[]});
      }
    } catch (e) {
      logger.error("setProjectedSkills() error :", e);
    }
  };
}

export function setSkillNotfiction(skill) {
  return async function (dispatch, getState) {
    try {
      if(skill){
        const { user: { occupationSkills=[] } } = getState();
        let targetOcc = false
        for (const occSkill of occupationSkills) {
          if(occSkill.skills.find((item) => item.name === skill) || (occSkill.optionalSkills && occSkill.optionalSkills.find((item) => item.name === skill)) ||(occSkill.deletedSkills && occSkill.deletedSkills.find((item) => item.name === skill))){
            targetOcc = occSkill.occupation
            break
          }
        }
        dispatch({type: SET_SKILL_NOTIFICATION, targetOcc: targetOcc, targetSkill:skill});
        dispatch(openUpdateSkills(targetOcc, skill))
      }
      else{
        dispatch({type: SET_SKILL_NOTIFICATION});
      }
    } catch (e) {
      logger.error("setSkillNotfiction() error :", e);
    }
  }
}

